<template>
  <div class="is-flex columns content-page">
    <left-panel-security/>
    <div class="column is-7">
      <div class="centered is-flex-direction-column py-6">
        <div dark-color bold class="login-main-text">{{ $t('welcome') }}</div>
        <div class="login-help-text font-size-15">{{ $t('login_help') }}</div>
        <div class="form-login centered is-flex-direction-column">
          <b-field>
            <b-input :placeholder="$t('email')"
                     type="email"
                     required
                     :validation-message="$t('invalid_email')"
                     v-model="username"
                     icon="account"
                     icon-clickable>
            </b-input>
          </b-field>
          <b-field class="mt-2">
            <b-input :placeholder="$t('password')"
                     type="password"
                     v-model="password"
                     icon="lock-outline"
                     required
                     @keyup.enter="login()"
                     :validation-message="$t('password_required')"
                     password-reveal
                     icon-clickable>
            </b-input>
          </b-field>
          <router-link :to="{name:'forgotPassword'}">
            <span class="forgot-text">{{ $t('is_forgot_password') }}</span>
          </router-link>

          <div class="buttons">
            <b-button @click="login" class="btn btn-insert" type="is-primary">{{ $t('login_in') }}</b-button>
          </div>

        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {mapMutations, mapActions} from "vuex";
import LeftPanelSecurity from "@/components/structure/leftPanelSecurity";
import {clearStorage} from "@/utils";

export default {
  name: "loginComponent",
  components: {LeftPanelSecurity},
  data() {
    return {
      username: null,
      password: null,
    }
  },
  methods: {
    ...mapMutations({
      setDash: 'SET_IS_DASH',
      setLoginPage: 'SET_IS_LOGIN'
    }),
    ...mapActions({
      doLogin: 'LOGIN',
      getTokenAuth0: 'LOGIN_AUTH0'
    }),
    login() {
      let data = {
        'email': this.username,
        'password': this.password
      }
      this.doLogin(data)
    },
  },
  created() {
    clearStorage()
    this.setDash(false)
    this.setLoginPage(true)
    this.getTokenAuth0()
  }
}
</script>

<style scoped lang="scss">

.forgot-text {
  cursor: pointer;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #28BCE8;
}

/* (1366x768) WXGA Display */
@media screen and (max-width: 1366px) {
  /* insert styles here */
  .login-main-text {
    font-size: 40px;
    margin-top: 80px;
  }
  .login-help-text {
    font-size: 12px;
  }
}

</style>